<template>
    <div class="main userinfo-page">
        <section class="main-width">
            <div class="row no-gutters">
                <div class="col-12 col-sm-2">
                    <div class="left-side">
                        <ul>
                            <li><router-link :to="{ name: 'profile' }"> {{$t("cabinet.organization")}} </router-link></li>
                            <li><router-link :to="{ name: 'procurement' }"> {{$t("cabinet.procurement_notice")}} </router-link></li>
                            <li><router-link :to="{ name: 'notifications' }"> {{$t("cabinet.notifications")}} </router-link></li>
                            <li><router-link :to="{ name: 'appeals' }"> {{$t("cabinet.appeals")}} </router-link></li>
                            <li><router-link :to="{ name: 'orders' }"> {{$t("cabinet.orders")}} </router-link></li>
                            <li><router-link :to="{ name: 'invitations' }"> {{$t("cabinet.invitation")}} </router-link></li>
                            <li><router-link :to="{ name: 'account-documents' }"> {{$t("cabinet.documents")}} </router-link></li>
                            <!-- <li><router-link :to="{ name: 'search-templates' }"> {{$t("cabinet.search_templates")}} </router-link></li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-10">
                    <router-view></router-view>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.main {
    padding-bottom: 0;
}
.userinfo-page {
    // padding-top: 60px;
    background: #fff;
}
</style>